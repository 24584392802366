var _ = require('lodash');

module.exports = {};

String.prototype.format = function () {
    var args = Array.prototype.slice.apply(arguments);
    var isArray = true;

    if (Array.isArray(args[0])) {
        args = args[0];
    } else if (typeof args[0] === 'object' && (args[0] !== null)) {
        args = args[0];
        isArray = false;
    }
    var ret = this.replace(/{([^{}]+)}/g, function (key, text) {
        if (args[text] !== undefined)
            return args[text];
        return key;
    });
    return ret;
};

module.exports.post = function () {
    var args = Array.prototype.slice.call(arguments);
    var opts = args[0];
    if (_.isString(opts))
        opts = args[1];
    opts.contentType = 'application/json';
    opts.dataType = 'json';
    opts.type = 'post';
    opts.data = JSON.stringify(opts.data);
    return $.ajax.apply(this, args);
};

module.exports.i18n = function (text) {
    return text;
};
