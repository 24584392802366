var React = require('react');
var ReactDOM = require('react-dom');
var _ = require('lodash');
var bootstrap = require('react-bootstrap');
var ContactModal = require('./contact');

var globals = {};

var Globals = React.createClass({
    render: function () {
        return <span/>;
    },
});

var register = function(name) {
    return function (component) {
        if (component)
            globals[name] = component;
    };
};

var contactModal, modal = <ContactModal ref={function (el) {
    if (el) contactModal = el;
}}/>;

var ContactButton = React.createClass({
    render: function () {
        return <button className='btn btn-primary btn-lg' role='button'
                onClick={function () { contactModal.setState({ showModal: true}); }}>
            Free consultation &raquo;
        </button>;
    }
});

var ContactButtonTop = React.createClass({
    render: function () {
        return <button className='btn btn-success' role='button'
                onClick={function () { contactModal.setState({ showModal: true}); }}>
            Contact Me
        </button>;
    }
});

var hooks = {
    'contactModal': modal,
    'btnContact': <ContactButton/>,
    'btnContactTop': <ContactButtonTop/>,
    'globals': <Globals ref={register('globals')}/>,
};

_.forEach(hooks, function (component, id) {
    ReactDOM.render(component, document.getElementById(id));
});
