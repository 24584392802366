var React = require('react');
var ReactDOM = require('react-dom');
var _ = require('lodash');
var bootstrap = require('react-bootstrap');
var validEmail = require('valid-email');
var util = require('./util');
var Recaptcha = require('react-recaptcha');
var i18n = util.i18n;

var Modal = bootstrap.Modal;
var Button = bootstrap.Button;
var Input = bootstrap.Input;
var TextArea = bootstrap.TextArea;
var OverlayMixin = bootstrap.OverlayMixin;

var InputMixin = {
    getInitialState: function () { return {}; },
    componentDidMount: function () {
        this.validate();
    },
    _getBaseProps: function () {
        return {
            ref: 'input',
            onChange: this.validate,
            placeholder: this.props.displayName,
            autoFocus: this.props.field == 'firstName',
            defaultValue: this.props.defaultValue,
        };
    },
};

var EmailInput = React.createClass({
    mixins: [ InputMixin ],
    validate: function () {
        var inputProps = {};
        if (this.refs.input.getValue().length > 0) {
            inputProps.hasFeedback = true;
            inputProps.bsStyle = validEmail(this.refs.input.getValue()) ? 'success' : 'error';
        }
        this.setState({ inputProps: inputProps });
    },
    render: function () {
        return <Input {...this._getBaseProps()} type='text' {...this.state.inputProps}/>;
    }
});

var MessageInput = React.createClass({
    mixins: [ InputMixin ],
    validate: function () {
        var inputProps = {};
        if (this.refs.input.getValue().length > 0) {
            inputProps.hasFeedback = true;
            inputProps.bsStyle = 'success';
        }
        this.setState({ inputProps: inputProps });
    },
    render: function () {
        return <Input className='contact-message' {...this._getBaseProps()} type='textarea' {...this.state.inputProps}/>;
    }
});

var fields = {
    email: { displayName: 'Your email', component: EmailInput },
    message: { displayName: 'Message', component: MessageInput },
};

if (false) {
    fields.email.defaultValue = 'x@jeffhansen.com';
    fields.message.defaultValue = 'Testing';
}

var ContactModal = React.createClass({
    mixins: [ OverlayMixin ],
    getInitialState: function () {
        return {
        };
    },
    _onClick: function () {
        var glob = globals.globals;
        this.setState({ showModal: true });
    },
    _onContactHide: function () {
        this.setState({ success: false, showModal: false })
    },
    _onContact: function () {
        var self = this;
        var req = {};
        var needCompleteContact = false, ok = true;
        _.forEach(fields, function (__, field) {
            var ref = self.refs[field], props = ref.state.inputProps;
            if (!props || !props.hasFeedback)
                needCompleteContact = true;
            else if (props.bsStyle != 'success')
                ok = false;

            if (field[0] == '_')
                return;
            req[field] = ref.refs.input.getValue();
        });

        if (needCompleteContact)
            ok = false;
        if (this.state.completeContact != needCompleteContact) {
            var error = undefined;
            if (needCompleteContact)
                error = i18n('Please fill out all fields!');
            this.setState({ error: error });
        }

        if (!ok)
            return;

        req.email = req.email.toLowerCase();
        req.captchaResponse = this.captchaResponse;
        util.post('/api/contact', { data: req })
        .then(function (res) {
            self.setState({ success: true });
        })
        .fail(function (res) {
            var error = res.responseJSON.error;
            if (error.match(/EXISTS:.*/)) {
                error = error.replace(/^EXISTS:/, i18n('Account already exists: '));
                error = <span>{error + i18n('. Did you forget your password?')}</span>;
            }
            self.setState({ error: error });
        });
    },
    renderForm: function () {
        var self = this;
        var elements = _.map(fields, function (info, field) {
            return React.createElement(info.component, {
                key: field, ref: field, field: field,
                displayName: info.displayName,
                defaultValue: info.defaultValue,
                parent: self,
            });
        });
        var classes = [ 'complete-contact' ];
        if (this.state.error)
            classes.push('visible');
        elements.push(<div key='error' className={classes.join(' ')}>{this.state.error}</div>);
        return <form>
            <div className='form-group'>
                {elements}
                <div className="g-recaptcha" data-sitekey={config.recaptcha.siteKey} ref={function (el) {
                    if (!el || self.grecaptcha == el) return;
                    self.grecaptcha = el;
                    console.log('RENDERING GRECAPTCHA');
                    grecaptcha.render(ReactDOM.findDOMNode(el), {
                        sitekey: config.recaptcha.siteKey,
                        callback: function (arg) { self.captchaResponse = arg; },
                    });
                }}></div>
            </div>
        </form>;
    },
    render: function () {
        if (this.state.showModal) {
            var button = <Button onClick={this._onContact}>Ping</Button>

            if (this.state.success) {
                form = 'You should receive a response soon.  Thanks!';
                button = <Button onClick={this._onContactHide}>Close</Button>;
            } else {
                var form = this.renderForm();
            }
        }

        return (<span>
            <Modal title='Contact Me' show={this.state.showModal} onHide={this._onContactHide}>
                <div className='modal-header'>
                    <div className='modal-close' onClick={this._onContactHide}><i className='fa fa-times'/></div>
                    <h2>Contact Me</h2>
                </div>
                <div className='modal-body'>
                    {form}
                </div>
                <div className='modal-footer'>
                    {button}
                </div>
            </Modal>
        </span>);
    },
});

module.exports = ContactModal;
